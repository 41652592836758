import i18n from 'i18n';

const options = changeLanguage => {
  return [
    {
      action: () => {
        changeLanguage('pt-BR');
      },
      text: i18n.t('PORTUGUESE_KEY'),
      language: /pt-BR/,
    },
    {
      action: () => {
        changeLanguage('en-US');
      },
      text: i18n.t('ENGLISH_KEY'),
      language: /en+-?\w*/,
    },
    {
      action: () => {
        changeLanguage('es');
      },
      text: i18n.t('SPANISH_KEY'),
      language: /es+-?\w*/,
    },
  ];
};

export default options;
