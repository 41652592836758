export default {
  root: {},
  input: {
    backgroundColor: '#fdfdfd',
    height: 56,
    padding: '8px 14px',
    fontSize: 14,
    boxSizing: 'border-box',
  },
  notchedOutline: {
    borderColor: 'rgba(0,0,0,0.15)',
  },
};
