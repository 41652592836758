import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { MessageInfo } from 'modules/messages/types';
import { messageClose } from 'modules/messages/actions';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

interface OwnProps {
  messages: MessageInfo[];
  messageClose: (a: number) => void;
}

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Messages: React.FC<OwnProps> = ({ messages, messageClose }) => {
  const classes = useStyles();

  const handleClose = (id: number) => messageClose(id);

  return (
    <React.Fragment>
      {messages.map(
        message =>
          !message.hasDisplayed && (
            <Snackbar
              key={message.type}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open
              autoHideDuration={8000}
              onClose={() => handleClose(message.id)}
            >
              <Alert
                classes={{ message: classes.message }}
                onClose={() => handleClose(message.id)}
                severity={message.type}
              >
                {message.message}
              </Alert>
            </Snackbar>
          )
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ messages }) => ({
  messages: messages.messagesList,
});

export default connect(mapStateToProps, { messageClose })(Messages);
