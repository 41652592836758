/* eslint-disable @typescript-eslint/camelcase */
import { handleActions } from 'redux-actions';
import { AuthState } from './interfaces';
import Types from './types';

const initialState: AuthState = {
  fields: {
    email: '',
    oldPassword: '',
    password: '',
    confirmPassword: '',
  },
  isLoading: false,
  restrictions: {
    email: '',
    restrictions: [],
    min_length: 0,
  },
  isAuthenticate: {
    access_token: '',
    mfa_configured: false,
    mfa_required: false,
    mfa_type: null,
    password_expiration: { will_expire_soon: false, days_until_expire: 0 },
    status: '',
    duo: {
      enabled: false,
      redirect_url: '',
    },
  },
  isLoadingForgotPassword: false,
  shouldUpdatePlugin: false,
  isLoadingRegisterPassword: false,
  totpQrcode: {
    totp_uri: '',
    qrcode_url: '',
  },
  autoLoginEnabled: false,
};

export default handleActions(
  {
    [Types.AUTH_CHECK_RESTRICTIONS_REQUEST]: (state: AuthState) => ({
      ...state,
      restrictions: initialState.restrictions,
      isLoading: true,
    }),
    [Types.AUTH_CHECK_RESTRICTIONS_FULFILLED]: (
      state: AuthState,
      { payload }
    ) => ({
      ...state,
      restrictions: payload,
      isLoading: false,
    }),
    [Types.AUTH_CHECK_RESTRICTIONS_REJECTED]: (state: AuthState) => ({
      ...state,
      isLoading: false,
    }),

    [Types.AUTH_CHANGE_FIELD]: (state: AuthState, { payload }) => ({
      ...state,
      fields: {
        ...state.fields,
        [payload.key]: payload.value,
      },
    }),
    [Types.AUTH_LOGIN_REQUEST]: (state: AuthState) => ({
      ...state,
      isLoading: true,
    }),
    [Types.AUTH_LOGIN_FULFILLED]: (state: AuthState, { payload }) => ({
      ...state,
      isLoading: false,
      isAuthenticate: payload,
      shouldUpdatePlugin: payload.shouldUpdatePlugin,
    }),
    [Types.AUTH_LOGIN_REJECTED]: (state: AuthState) => ({
      ...state,
      isLoading: false,
    }),

    [Types.AUTH_LOGIN_WITH_SSO_REQUEST]: (state: AuthState) => ({
      ...state,
    }),
    [Types.AUTH_LOGIN_WITH_SSO_FULFILLED]: (state: AuthState, { payload }) => ({
      ...state,
      isLoading: false,
      isAuthenticate: payload,
      shouldUpdatePlugin: payload.shouldUpdatePlugin,
    }),
    [Types.AUTH_LOGIN_WITH_SSO_REJECTED]: (state: AuthState) => ({
      ...state,
      isLoading: false,
    }),

    [Types.AUTH_LOGIN_REQUIRED_MFA_REQUEST]: (state: AuthState) => ({
      ...state,
      isLoading: true,
    }),
    [Types.AUTH_LOGIN_REQUIRED_MFA_FULFILLED]: (
      state: AuthState,
      { payload }
    ) => ({
      ...state,
      isLoading: false,
      isAuthenticate: { ...state.isAuthenticate, ...payload },
      shouldUpdatePlugin: payload.shouldUpdatePlugin,
    }),
    [Types.AUTH_LOGIN_REQUIRED_MFA_REJECTED]: (state: AuthState) => ({
      ...state,
      isLoading: false,
    }),
    [Types.AUTHENTICATE_SSO_REQUEST]: (state: AuthState) => ({
      ...state,
      isLoading: true,
    }),
    [Types.AUTHENTICATE_SSO_FULFILLED]: (state: AuthState, { payload }) => ({
      ...state,
      isLoading: false,
      isAuthenticate: payload,
      shouldUpdatePlugin: payload.shouldUpdatePlugin,
    }),
    [Types.AUTHENTICATE_SSO_REJECTED]: (state: AuthState) => ({
      ...state,
      isLoading: false,
    }),

    [Types.GET_TOTP_QRCODE_REQUEST]: (state: AuthState) => ({
      ...state,
      isLoading: true,
    }),
    [Types.GET_TOTP_QRCODE_FULFILLED]: (state: AuthState, { payload }) => ({
      ...state,
      isLoading: false,
      totpQrcode: payload,
      shouldUpdatePlugin: payload.shouldUpdatePlugin,
    }),
    [Types.GET_TOTP_QRCODE_REJECTED]: (state: AuthState) => ({
      ...state,
      isLoading: false,
    }),

    [Types.AUTH_FORGOT_PASSWORD_REQUEST]: (state: AuthState) => ({
      ...state,
      isLoadingForgotPassword: true,
    }),
    [Types.AUTH_FORGOT_PASSWORD_FULFILLED]: (state: AuthState) => ({
      ...state,
      isLoadingForgotPassword: false,
    }),
    [Types.AUTH_FORGOT_PASSWORD_REJECTED]: (state: AuthState) => ({
      ...state,
      isLoadingForgotPassword: false,
    }),

    [Types.AUTH_CHANGE_PASSWORD_REQUEST]: (state: AuthState) => ({
      ...state,
      isLoadingForgotPassword: true,
    }),
    [Types.AUTH_CHANGE_PASSWORD_FULFILLED]: (state: AuthState) => ({
      ...state,
      isLoadingForgotPassword: false,
    }),
    [Types.AUTH_CHANGE_PASSWORD_REJECTED]: (state: AuthState) => ({
      ...state,
      isLoadingForgotPassword: false,
    }),

    [Types.AUTH_UPDATE_PLUGIN]: (state: AuthState) => ({
      ...state,
      shouldUpdatePlugin: false,
    }),

    [Types.AUTH_REGISTER_REQUEST]: (state: AuthState) => ({
      ...state,
      isLoadingRegisterPassword: true,
    }),

    [Types.AUTH_REGISTER_FULFILLED]: (state: AuthState) => ({
      ...state,
      isLoadingRegisterPassword: false,
    }),

    [Types.AUTH_REGISTER_REJECTED]: (state: AuthState) => ({
      ...state,
      isLoadingRegisterPassword: false,
    }),

    [Types.AUTH_CLEAR_FIELDS]: (state: AuthState) => ({
      ...state,
      fields: {
        ...initialState.fields,
      },
    }),

    [Types.AUTH_LOGOUT]: () => initialState,

    [Types.ENABLE_AUTO_LOGIN]: (state: AuthState) => ({
      ...state,
      autoLoginEnabled: true,
    }),
    [Types.DISABLE_AUTO_LOGIN]: (state: AuthState) => ({
      ...state,
      autoLoginEnabled: false,
    }),
  },
  initialState
);
