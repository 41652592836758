import { createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export default (overrideClasses: any, { breakpoints }: Theme) => () =>
  createStyles({
    root: {
      height: 45,
      borderBottom: '1px solid #EBEBED',
      [breakpoints.up('lg')]: {
        height: 115,
        padding: '0 188px',
      },
    },
    upperSection: {
      padding: '0px 26px',
      fontFamily: 'Roboto',
      fontSize: 10,
      fontWeight: 900,
      color: '#979797',
      letterSpacing: 0,
      [breakpoints.up('lg')]: {
        fontSize: 22,
        padding: '0',
      },
    },
    subSection: {
      fontFamily: 'Roboto',
      fontSize: 10,
      fontWeight: 900,
      letterSpacing: 0,
      [breakpoints.up('lg')]: {
        fontSize: 22,
        padding: '0',
      },
    },
    button: {
      width: 178,
      height: 46,
      backgroundColor: '#07BF6F',
      fontSize: 15,
      color: '#fff',
    },
    subText: {
      fontSize: 8,
      textAlign: 'center',
    },
    ...overrideClasses,
  });
