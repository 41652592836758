import { takeLatest, put, all, select } from 'redux-saga/effects';
import { isRequestOK } from 'utils/helpers';
import {
  instanceRequest,
  instanceRejected,
  instanceFulfilled,
} from './actions';
import endpoints, { api } from './endpoints';
import { pluginHandle } from 'modules/clients/utils';

function* instanceRequestSaga() {
  try {
    const {
      clients: { uuid },
    } = yield select();

    const { status, data } = yield api.get({
      url: endpoints.login_attempt(uuid),
    });
    yield pluginHandle(data, status, uuid);

    return yield isRequestOK(status)
      ? all([put(instanceFulfilled(data.instance))])
      : all([put(instanceRejected())]);
  } catch (responseWithError) {
    return yield responseWithError &&
    responseWithError.response &&
    responseWithError.response.status === 452
      ? put(instanceRejected())
      : all([put(instanceRejected())]);
  }
}

export default [takeLatest(instanceRequest, instanceRequestSaga)];
