export default {
  root: {
    padding: '0',
  },
  dot: {
    width: 18,
    height: 6,
    borderRadius: '30%',
  },
  dotActive: {
    backgroundColor: '#006BDE',
  },
};
