import { createAction } from 'redux-actions';
import Types from './types';

export const clientsRequest = createAction(Types.CLIENTS_REQUEST);
export const clientsFulfilled = createAction(Types.CLIENTS_FULFILLED);
export const clientsRejected = createAction(Types.CLIENTS_REJECTED);

export const selectedClient = createAction(Types.CLIENT_SELECTED);
export const selectedClientFulfilled = createAction(
  Types.CLIENT_SELECTED_FULFILLED
);
export const selectedClientRejected = createAction(
  Types.CLIENT_SELECTED_REJECTED
);

export const clientLoginAttemptRequest = createAction(
  Types.CLIENT_LOGIN_ATTEMPT_REQUEST
);
export const clientLoginAttemptFulfilled = createAction(
  Types.CLIENT_LOGIN_ATTEMPT_FULFILLED
);
export const clientLoginAttemptRejected = createAction(
  Types.CLIENT_LOGIN_ATTEMPT_REJECTED
);

export const clientSetData = createAction(Types.CLIENT_SET_DATA);

export const clientSessionRequest = createAction(Types.CLIENT_SESSION_REQUEST);

export const clientSessionPut = createAction(Types.CLIENT_SESSION_PUT);

export const clientSetLoading = createAction(Types.CLIENT_SET_LOADING);

export const clientsResetState = createAction(Types.CLIENTS_RESET_STATE);

export const registerSelectedVirtualizerRequest = createAction(
  Types.REGISTER_SELECTED_VIRTUALIZER_REQUEST
);
export const registerSelectedVirtualizerFulfilled = createAction(
  Types.REGISTER_SELECTED_VIRTUALIZER_FULFILLED
);
export const registerSelectedVirtualizerRejected = createAction(
  Types.REGISTER_SELECTED_VIRTUALIZER_REJECTED
);

export const registerSelectedVirtualizerLinuxRequest = createAction(
  Types.REGISTER_SELECTED_VIRTUALIZER_LINUX_REQUEST
);

export const registerSelectedVirtualizerRequestCompleted = createAction(
  Types.REGISTER_SELECTED_VIRTUALIZER_REQUEST_COMPLETED
);

export const setIframe = createAction(Types.SET_IFRAME);

export const getWebAppsRequest = createAction(Types.GET_WEB_APPS_REQUEST);
export const getWebAppsFulfilled = createAction(Types.GET_WEB_APPS_FULFILLED);
export const getWebAppsRejected = createAction(Types.GET_WEB_APPS_REJECTED);
