export default {
  INVALID_USER: 'Usuário ou senha inválidos.',
  INVALID_TOKEN: 'Token inválido',
  EMAIL_ALREADY_IN_USE: 'E-mail inserido já está em uso',
  TELEPHONE_ALREADY_IN_USE: 'Telefone inserido já está em uso',
  INVALID_ID: 'ID inválido',
  INVALID_BANK: 'Banco inválido',
  INVALID_METADATA: 'Metadados inválidos',
  ENTITY_NOT_FOUND: 'Entidade não encontrada',
  INVALID_EMAIL: 'E-mail inválido',
  INVALID_VALUE: 'Valor inválido',
  INVALID_FILE: 'Arquivo inválido',
  INVALID_BANK_NUMBER: 'Número do banco inválido',
  DUPLICATED_VALUE: 'Valor duplicado',
  IS_NOT_A_NUMBER: 'Não é um número',
  LENGTH_TOO_SHORT: 'Muito curto',
  LENGTH_TOO_LONG: 'Muito longo',
  IS_REQUIRED: 'É obrigatório',
  USER_NOT_FOUND: 'Usuário e/ou senha inválidos',
  NO_LICENSE_ENVIRONMENT: 'O número de licenças atingiu o seu limite máximo.',
  USER_ACCESS_IS_RESTRICTED: 'O acesso do usuário é restrito',
  NO_INSTANCE_AVAILABLE:
    'Ambiente não configurado. Entre em contato com o seu departamento de TI para liberação do ambiente',
};
