enum Types {
  CONFIG_REQUEST = 'CONFIG_REQUEST',
  CONFIG_FULFILLED = 'CONFIG_FULFILLED',
  CONFIG_REJECTED = 'CONFIG_REJECTED',
  CONFIG_TRY_GET_BASE_CONFIG = 'CONFIG_TRY_GET_BASE_CONFIG',

  CONFIG_CHANGE_SHOW_NEWS = 'CONFIG_CHANGE_SHOW_NEWS',
}

export default Types;
