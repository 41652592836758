/* eslint-disable max-lines */

export default {
  translation: {
    VERSION_KEY: 'Versão',
    PORTUGUESE_KEY: 'Português',
    ENGLISH_KEY: 'Inglês',
    SPANISH_KEY: 'Espanhol',
    SELECT_CLIENT_KEY: 'Selecione um cliente para continuar',
    HOME_KEY: 'Autosky',
    ENVIRONMENTS_KEY: 'Ambientes',
    RECAPTCHA_ERROR_KEY: 'Erro na validação do recaptcha. Tente novamente',
    MAXIMUM_LOGIN_ATTEMPTS:
      'Usuário inativo: Você excedeu o número máximo de tentativas de login. Procure o administrador!',
    ACTIONS_KEY: 'Ações',
    CLIENTS_NAME_KEY: 'Clientes',
    MAINTENANCE_KEY: 'Manutenção',
    TIME_INSTANCE_KEY: 'Encontrando a instância',
    FIRST_ACCESS_KEY: 'Olá! Seja bem-vindo.',
    FIRST_ACCESS_SECOND_KEY:
      'Este é seu primeiro acesso na plataforma Autosky?',
    CLICK_HERE_TO_CONNECT_KEY: 'Clique para conectar',
    ADD_PLUGIN_KEY:
      'Para iniciar sua sessão corretamente, realize a instalação do Plugin de conexão em seu computador.',
    HAS_NO_PLUGIN_KEY:
      'Caso seja seu primeiro acesso, você deve realizar a instalação do plugin de conexão.',
    HAS_INSTALLED_PLUGIN_KEY: 'Você já instalou o plugin de conexão?',
    YES_KEY: 'Sim',
    NO_KEY: 'Não',
    PENDING_CONNECTIONS_KEY: 'Conexões pendentes',
    TITLE_FORGOT_PASSWORD_KEY: 'Esqueceu a senha?',
    FORGOT_PASSWORD_KEY: 'Esqueci minha senha',
    FORGOT_PASSWORD_TEXT_2_KEY: 'Digite seu e-mail abaixo',
    FORGOT_PASSWORD_TEXT_KEY: 'Preencha os dados para alterar sua senha.',
    FORGOT_PASSWORD2_TEXT_KEY: 'Evite senhas fáceis ou apenas números.',
    BACK_KEY: 'Voltar',
    SEND_KEY: 'Enviar',
    CHANGE_KEY: 'Alterar',
    RECOVER_KEY: 'Recuperar',
    WELCOME_KEY: 'Seja bem-vindo!',
    DESCRIPTION_LOGIN_KEY:
      'Faça seu login para iniciar o acesso ao seu sistema de forma online, com dados criptografados e armazenados em ambientes seguros!',
    NO_ITEMS_FOUND: 'Nenhum ambiente encontrado com seus critérios de busca.',
    FORGOT_PASSWORD_HEADER_KEY: `Recuperação de Senha`,
    CHANGE_PASSWORD_TITLE_KEY: `Nova Senha`,
    FORGOT_PASSWORD_DESCRIPTION_KEY:
      'Digite seu e-mail e clique em Recuperar para receber um e-mail de recadastro de senha.',
    PRIVACY_POLICY_KEY: 'Política de Privacidade',
    CODE_OF_ETHICS_AND_CONDUCT_KEY: 'Termos e Condições',
    NEW_PASSWORD_KEY: 'Cadastre sua senha',
    NEW_PASSWORD_KEY_2: 'Nova Senha',
    NEW_PASSWORD_DESCRIPTION_KEY:
      'Cadastre ao lado sua nova senha. Utilize letras, números e símbolos para aumentar a segurança.',
    HEADER_LOGIN_KEY: 'Rápida e segura.',
    EMAIL_KEY: 'E-mail',
    OLD_PASSWORD_KEY: 'Senha Antiga',
    PASSWORD_KEY: 'Senha',
    CONFIRM_PASSWORD_KEY: 'Confirmação de senha',
    CONFIRM_NEW_PASSWORD_KEY: 'Confirmar a nova senha',
    NO_CLIENTS_KEY: 'Nenhum cliente encontrado!',
    CONNECT_KEY: 'Conectar',
    SEARCH_KEY: 'Pesquisar',
    TYPE_YOUR_NEW_PASSWORD_KEY: 'Digite a sua nova senha',
    SEARCH_ENVIRONMENT_KEY: 'Digite aqui para encontrar um ambiente...',
    YOU_HAVE_KEY: 'Você possui o total de ',
    NO_LICENSE_CLIENT: 'Sem licença do virtualizador.',
    ENVIRONMENT_IN_AUTO_SKY_KEY: '',
    SOMETHING_BAD_HAPPENED: 'Algo estranho aconteceu. Tente novamente.',
    INITIATING_AUTO_SKY_KEY: 'Iniciando sua sessão de usuário...',
    THIS_CAN_TAKE_KEY: 'Este procedimento pode levar até',
    EIGHT_MINUTES_KEY: ' 8 minutos;',
    FIVE_MINUTES_KEY: ' 5 minutos;',
    TWO_MINUTES_KEY: ' 2 minutos;',
    YOU_WILL_BE_REDIRECT_KEY: 'você será redirecionado automaticamente',
    OPS_KEY: 'Seu ambiente está em manutenção',
    MAINTENANCE_RUNNING_KEY:
      'O ambiente selecionado está indisponível ou em manutenção pelos administradores do sistema.',
    ACCESS_KEY: 'Acessar',
    SESSION_ACTIVE_1_KEY:
      'Identificamos que existe uma sessão ativa para seu usuário.',
    SESSION_ACTIVE_2_KEY: 'Deseja encerrar sua sessão antiga e continuar?',
    SESSION_ACTIVE_3_KEY:
      'Clique em continuar para encerrar a sessão e prosseguir.',
    WHY_YOU_RECEIVE_THIS_KEY: 'Por que você está recebendo essa mensagem?',
    SESSION_IDENTIFIED:
      'Você pode ter sofrido uma desconexão momentânea de internet ou não ter encerrado sua conexão anterior corretamente.',
    FINALIZE_PREVIOUS_SESSION:
      'Caso esse cenário persista, entre em contato com seu administrador do sistema.',
    CONTINUE_KEY: 'Continuar',
    WAIT_A_MOMENT_KEY:
      'Aguarde um momento e não se preocupe, você será automaticamente conectado assim que estivermos prontos.',
    LOCATING_ENVIRONMENT:
      'Estamos localizando o melhor espaço para seu acesso.',
    REQUIRED_FIELD: 'Campo obrigatório!',
    ENTER_KEY: 'Entrar',
    ENTER_EMAIL_KEY: 'Entrar com e-mail',
    CHANGE_PASSWORD_KEY: 'Modificar minha senha',
    CANCEL_KEY: 'Cancelar',
    VIRTUALIZER_CHOICE_KEY: 'Virtualizador',
    MAX_LENGTH_PASSWORD: 'Máximo de caracteres para a senha é de 40.',

    USER_NOT_FOUND:
      'E-mail ou Senha inválidos. Por favor, entre novamente com os dados.',
    WRONG_USER_KEY: 'Usuário não encontrado',
    ERROR_REQUEST_KEY: 'Algo estranho aconteceu. Tente novamente.',
    MFA_ERROR_KEY: 'Erro ao autenticar o MFA. Tente novamente',
    VERIFY_YOUR_EMAIL_KEY: 'Uma mensagem foi enviada para seu e-mail.',
    WARNING_MESSAGE: 'Mensagem de Aviso:',
    UPDATED_ACCOUNT_KEY: 'Senha alterada com sucesso.',
    EMAIL_OR_PASSWORD_DONT_MATCH_KEY:
      'E-mail ou senha não foram encontrados ou não combinam.',
    EMAIL_INVALID_KEY: 'Formato de e-mail inválido!',
    ERROR_CHANGING_PASSWORD_KEY: 'Erro ao alterar sua senha.',
    ENVIRONMENT_NOT_CONFIGURED_KEY:
      'Não existem mais licenças disponíveis para este ambiente. Procure o administrador do sistema.',
    VIRTUALIZER_NOT_FOUND: 'Virtualizador não encontrado.',
    UPDATE_KEY: 'Atualizar',
    UPDATE_PLUGIN_KEY: 'Atualização de plugin de conexão',
    UPDATE_YOUR_PLUGIN_KEY: 'Atualize o seu plugin',
    UPDATE_YOUR_PLUGIN_TEXT_KEY:
      'Olá, existe uma atualização de plugin de conexão para você continuar com sua conexão.',
    UPDATE_YOUR_PLUGIN_TEXT2_KEY2:
      'Clique no botão CONTINUAR para seguir para a atualização.',
    FOLLOW_THE_STEPS_BELOW_KEY: 'Siga os passos abaixo.',
    MIN_KEY: 'Mínimo de {{value}} letras',
    MAX_KEY: 'Máximo de {{value}} letras',
    PASSWORD_DOESNT_MATCH: 'As senhas informadas são diferentes.',
    PASSWORD_MUST_BE_DIFFERENT:
      'A nova senha deve ser diferente da senha antiga.',

    PASSWORD_MUST_CONTAIN_KEY: 'A senha deve conter',
    REQUIRE_UPPERCASE_TEXT_KEY: 'Letras maiúsculas',
    REQUIRE_LOWERCASE_TEXT_KEY: 'Letras minúsculas',
    REQUIRE_NUMBER_TEXT_KEY: 'Números',
    REQUIRE_SYMBOL_TEXT_KEY: 'Caracteres especiais',
    REQUIRE_MIN_LENGTH_TEXT_KEY: 'Mínimo de {{min_length}} caracteres',
    REQUIRE_MAX_LENGTH_TEXT_KEY: 'Máximo de {{max_length}} caracteres',
    REQUIRE_PASSWORD_DIFF_KEY:
      'A nova senha deve ser diferente da senha antiga',

    LAUNCHING_KEY: 'Iniciando...',
    PLEASE_CLICK_KEY: 'Clique em',
    AUTO_SKY_PLUGIN_KEY: 'Autosky Plugin',
    SEE_DIALOG_KEY: 'para que sua sessão seja iniciada.',
    IF_NOT_KEY: 'Se a caixa de diálogo não aparecer,',
    CLICK_HERE_KEY: 'clique aqui ',
    IF_DIALOG_NOT_SOWING_KEY:
      'Se mesmo assim a caixa de diálogo não aparecer, ',
    GO_TO_INSTALL_KEY: 'clique aqui para seguir os passos da instalação ',
    PLUGIN_IN_COMPUTER_KEY: 'do plugin em seu computador.',
    LAUNCH_KEY: 'para visualizar a janela novamente.',
    DOWNLOAD_AND_RUN_KEY: 'clique aqui para seguir os passos da instalação.',
    STARTING_HTML5_INSTANCE: 'A sua sessão está sendo estabelecida...',
    SUB_TEXT_LOADING_VIRTUALIZER_KEY:
      'Migre seus sistemas e aplicações cliente-servidor para a nuvem.',
    LOADING_KEY: 'Carregando',
    ESTABLISHING_CONNECTION_KEY: 'Estabelecendo a conexão',

    CONNECTION_FINISHED_KEY: 'Conexão estabelecida!',
    SUCCESS_LOADING_IF_NOT_KEY:
      'Caso sua aplicação ainda não tenha sido aberta',
    SUCCESS_LOADING_CONFIRM_KEY: 'para confirmar a instalação do plugin',

    CHOOSE_VIRTUALIZER_KEY: 'Selecione a forma de acesso de sua preferência:',
    CHOOSE_VIRTUALIZER_SUB_TEXT_KEY:
      'Ao selecionar a opção desejada você será direcionado para a conexão do seu ambiente.',
    PC_KEY: 'Computador',
    BROWSER_KEY: 'Navegador',
    OPEN_AT_KEY: 'Abrir no {{content}}',

    INSTALL_KEY: 'Instalar',
    HERE_ENVIRONMENT_KEY:
      'Selecione o ambiente na lista abaixo para acessar seu sistema.',

    INSTALL_PLUGIN: {
      INSTALLATION_KEY: 'Instalação do Plugin Autosky de Conexão',
      STEP_TO_STEP_KEY: 'Instruções de instalação:',
      IF_NOT_KEY: 'Caso o download não seja realizado, ',
      IN_CASE_APPLICATION_DIDNT_APPEAR_ONE:
        'Caso sua aplicação ainda não tenha sido aberta, ',
      CONFIRM_INSTALLATION: 'para confirmar a instalação do plugin',
      TO_CONTINUE_KEY: 'para efetuar a instalação manualmente',

      FIRST_STEPS: {
        ONE: '01. Clique no botão "Instalar";',
        TWO:
          '02. Você será redirecionado para a tela com as instruções de instalação do plugin;',
        THREE: '03. Siga os passos e acesse seu ambiente.',
      },

      LAST_STEPS: {
        ONE: '01. Confirme o download automático do arquivo de instalação',
        TWO: '02. Execute a instalação do arquivo em seu Windows ou MAC',
        THREE:
          '03. Após a instalação concluída em seu computador, clique em AVANÇAR',
      },
    },
    NEXT_KEY: 'Avançar',

    NOT_FOUND_PAGE_KEY: 'Página não encontrada',
    GO_TO_HOME_KEY: 'Voltar para home',

    DESCRIPTION_MFA_KEY:
      'Com a plataforma Autosky, você leva seu software monolítico para a nuvem, sem mexer em nenhuma linha de código, com banco de dados e usuários configurados em poucas horas',

    TURN_BACK_KEY: 'Retornar',
    AUTHENTICATION_CODE_KEY: 'Insira o código de autenticação',
    PANEL_AUTO_SKY: 'Painel Autosky',
    CONFIGURE_MFA_KEY: 'Configure o seu código de autenticação MFA.',
    INSERT_MFA_KEY: 'Insira o código de autenticação do seu MFA',

    MODAL_VERSION_TITLE_KEY: 'Atualização de versão disponível',
    MODAL_VERSION_CONTENT_KEY:
      'Há uma atualização disponível para a sua aplicação. Clique para atualizar sem perder a sessão atual.',
    MODAL_BUTTON_KEY: 'Atualizar versão',

    NOT_INPUT_DATA: 'Não há dados fornecidos',
    USER_NOT_ACTIVE: 'Usuário não está ativo',
    INVALID_EMAIL: 'E-mail inválido',
    INVALID_PASSWORD: 'Senha inválida',
    USER_AUTHENTICATED: 'Usuário autenticado',
    AUTH_FAILURE: 'Autenticação falhou. Por favor, tente novamente.',
    PASSWORD_EXPIRED: 'A senha expirou. Redefina uma nova senha de acesso.',
    PASSWORD_EXPIRES_SOON: 'A senha irá expirar em breve.',
    UNABLE_UPDATE_PASSWORD: 'Não foi possível atualizar a senha.',
    ERROR_UPDATING_PASSWORD: 'Erro ao atualizar a senha.',
    PASSWORD_UPDATED: 'Senha atualizada com sucesso!',
    INVALID_TYPE: 'Tipo inválido',
    NOT_NULL: 'Este campo não pode ser nulo.',
    VALIDATOR_FAILED: 'Validação incorreta.',
    INVALID_VALUE: 'Valor inválido',
    ERROR_IN_ACCESS_VALIDATION:
      'Erro na validação do acesso no Active Directory.',
    EXTERNAL_USER: 'Usuário externo',
    SUCCESS: 'Sucesso',
    HAS_PENDING_CONNECTION: 'Você tem conexões pendentes',
    PASSWORD_CHANGE_ERROR: 'Erro ao alterar a senha.',
    PASSWORD_CHANGE_SUCCESS: 'Alteração de senha bem-sucedida!',
    CONTACT_YOUR_SYSTEM_ADMINISTRATOR:
      'Contate o seu administrador para mudar sua senha',
    EXPIRED_MAIL: 'E-mail expirado.',
    ERROR_TO_SEND_DISCONNECT:
      'Erro ao desconectar usuários com conexões pendentes.',
    ENVIRONMENT_MAINTENANCE_WITHOUT_MESSAGE:
      'Manutenção de ambiente configurada sem mensagem.',
    USER_NO_OTP_DATA: 'OTP não está configurado para o seu usuário.',
    USER_OTP_ALREADY_CONFIGURED: 'OTP já está configurado para o seu usuário.',
    USER_OTP_INVALID: 'OTP inválido.',
    CLIENT_MAINTENANCE_WITHOUT_MESSAGE:
      'Mensagem de manutenção não configurada para o cliente.',
    ENVIRONMENT_WARNING_WITHOUT_MESSAGE:
      'Mensagem de aviso não configurada para o ambiente',
    CLIENT_WARNING_WITHOUT_MESSAGE:
      'Mensagem de aviso não configurada para o cliente',
    UNSENT_EMAIL: 'E-mail não enviado',
    CLIENT_NOT_FOUND: 'Cliente não encontrado',
    CALL_CLIENT_PLUGIN: 'Chamada ao plug-in de cliente',
    SOMETHING_WENT_WRONG: 'Alguma coisa não deu certo. Tente novamente.',
    PAYLOAD_NOT_RIGHT: 'Dados enviados pelo payload não estão corretos.',
    INVALID_RECAPTCHA: 'Recaptcha inválido. Tente novamente.',
    IDP_NOT_FOUND: 'Identity Provider não encontrado',
    IDP_AUTH_LOGIN_REDIRECT_ERROR:
      'Não foi possível autenticar no Identity Provider',
    ACS_PROCESS_ASSERTION_ERROR:
      'Não foi possível encontrar o processo ACS do plug-in',
    RELAY_STATE_ERROR: 'RELAY_STATE_ERROR',
    INVALID_ACCESS_TOKEN: 'Token de acesso inválido.',
    CORPORATE_TITLE_KEY: 'Entrar com Credenciais Corporativas',
    CORPORATE_CREDENTIALS_KEY: 'Credenciais corporativas',
    UNEXPECTED_ERROR: 'Ocorreu um erro inesperado',
    CORPORATE_CREDENTIALS_DESCRIPTION_KEY:
      'Para acessar o Autosky usando Credenciais Corporativas, entre no campo abaixo o código de Ambiente Corporativo fornecido pelo seu Administrador',
    SSO_INVALID_ACCESS_TOKEN:
      'Token de acesso invalido, por favor refaça o login.',
    SSO_CORPORATE_CREDENTIALS_NOT_FOUND:
      'Credencial corporativa não encontrada.',
    SSO_ERROR_ON_GET_CORPORATE_CREDENTIALS:
      'Erro ao obter credencial corporativa, por favor entre em contato com o administrador.',
    SSO_ERROR_ON_PREPARING_CORPORATE_CREDENTIALS:
      'Erro ao obter credencial corporativa identificada, por favor entre em contato com o administrador.',
    SSO_CORPORATE_CREDENTIAL_NOT_ENABLED:
      'Credencial corporativa identificada não esta ativa, por favor entre em contato com o administrador.',
    SSO_AUTH_LOGIN_REDIRECT_ERROR:
      'Erro ao redirecionar para o site externa de credenciais corporativas, por favor entre em contato com o administrador.',
    SSO_ACS_PROCESS_ASSERTION_ERROR:
      'Erro ao processar a resposta da credencial corporativa externa, por favor entre em contato com o administrador.',
    SSO_ACS_RESPONSE_ASSERTION_ERROR:
      'Documento de resposta da credencial corporativa é inválido, por favor entre em contato com o administrador.',
    SSO_ACS_RESPONSE_HAS_EXPIRED:
      'Processo de login por credencial corporativa foi expirado, por favor tente novamente.',
    SSO_ACS_RESPONSE_IS_INVALID:
      'Erro ao validar resposta de credencial corporativa, por favor tente novamente.',
    SSO_ERROR_ON_GET_SAML_ATTRIBUTES:
      'Erro ao obter detalhes do usuário via credencial corporativa, por favor tente novamente.',
    SSO_MISSING_SAML_NAME_ID_CLAIM:
      'O sistema externo de credencial corporativa, não retornou o atributo [Name ID] com o nome único do usuário, logon ou UPN. Por favor entre em contato com o administrador.',
    SSO_MISSING_SAML_GIVEN_NAME_CLAIM:
      'O sistema externo de credencial corporativa, não retornou o atributo [Given Name] com o primeiro nome do usuário. Por favor entre em contato com o administrador.',
    SSO_MISSING_SAML_SURNAME_CLAIM:
      'O sistema externo de credencial corporativa, não retornou o atributo [Surname] com o sobrenome do usuário. Por favor entre em contato com o administrador.',
    SSO_MISSING_SAML_EMAIL_ADDRESS_CLAIM:
      'O sistema externo de credencial corporativa, não retornou o atributo [Email] com o endereço de email do usuário. Por favor entre em contato com o administrador.',
    SSO_SAML_NAME_ID_CLAIM_TOO_LARGE:
      'O sistema externo de credencial corporativa, retornou o atributo [Name ID] com o nome único do usuário, logon ou UPN, com uma quantidade maior de caracteres permitida. Por favor entre em contato com o administrador.',
    SSO_SAML_GIVEN_NAME_CLAIM_TOO_LARGE:
      'O sistema externo de credencial corporativa, retornou o atributo [Given Name] com o primeiro nome do usuário, com uma quantidade maior de caracteres permitida. Por favor entre em contato com o administrador.',
    SSO_SAML_SURNAME_CLAIM_TOO_LARGE:
      'O sistema externo de credencial corporativa, retornou o atributo [Surname] com o sobrenome do usuário, com uma quantidade maior de caracteres permitida. Por favor entre em contato com o administrador.',
    SSO_SAML_EMAIL_ADDRESS_CLAIM_TOO_LARGE:
      'O sistema externo de credencial corporativa, retornou o atributo [Email] com o endereço de email do usuário, com uma quantidade maior de caracteres permitida. Por favor entre em contato com o administrador.',
    SSO_USER_IS_BEING_CREATED_WAIT_IT:
      'O usuário de credencial corporativa já esta sendo criado em uma outra sessão, por favor tente novamente.',
    USER_NOT_PERMITTED_SSO_CLIENT:
      'O acesso não pôde ser estabelecido pois o cliente possui habilitada a configuração de uso de credenciais corporativas.',
    USER_NOT_PERMITTED_SSO_ENABLED:
      'Acesso inválido usando-se credenciais corporativas.',
    USER_ACCESS_IS_RESTRICTED: 'O acesso do usuário é restrito',
    ACCESS_RESTRICTED_TITLE_KEY: 'Você não está autorizado para acessar agora',
    ACCESS_RESTRICTED_IP_KEY:
      'Verificar com o administrador do sistema se seu endereço IP ({{ip}}) possui acesso a plataforma',
    ACCESS_RESTRICTED_SCHEDULE_KEY:
      'Seu acesso ser liberado às {{start}} ({{timeZone}}), conforme configurações definidas pelo administrador da conta',
    DO_YOU_CHANGE_YOUR_PASSWORD:
      'Você deseja redefinir sua senha de acesso agora?',
    CHANGE_PASSWORD_NOW: 'Sim, definir agora',
    NOT_CHANGE_PASSWORD_NOW: 'Não, definir depois',
    DAYS_TO_CHANGE_PASSWORD: 'Sua senha vai expirar em {{days}} {{letter}}',
    DAY_KEY: 'dia',
    DAYS_KEY: 'dias',
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_WRONG_CONFIG:
      'Erro ao obter credencial corporativa para validar grupos. Configurações inválidas. Por favor entre em contato com o administrador.',
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_MISSING_ATTRIBUTE:
      "Erro ao obter credencial corporativa para validar grupos. O atributo 'Grupo' não foi encontrado. Por favor entre em contato com o administrador.",
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_UNKNOWN_FORMAT:
      'Erro ao obter credencial corporativa para validar grupos. O atributo está em um formato inválido. Por favor entre em contato com o administrador.',
    SSO_ERROR_ON_VALIDATE_USER_GROUPS_FORBIDDEN:
      'Erro ao obter credencial corporativa para validar grupos. O usuário não tem acesso. Por favor entre em contato com o administrador.',

    FIRST_ACCESS_CANCEL_KEY: 'Já tenho o plugin instalado',
    FIRST_ACCESS_CONFIRM_KEY: 'Instalar agora',
    COPY_KEY: 'Copiar',
    COPIED_KEY: 'Copiado',
    CLICK_TO_COPY_KEY: 'Clique aqui para copiar',
    MFA_TYPE_ERROR: 'Tipo de autenticação incorreta',
    DUO_SETTINGS_NOT_FOUND:
      'As configurações de autenticação com o DUO não foram encontradas',
    DUO_STATE_MISMATCH: 'Token de acesso DUO incorreto',
    DUO_ERROR_TO_AUTHENTICATE: 'Erro na autenticação do usuário com o DUO',
    DESKTOP_APPS_KEY: 'Aplicações Desktop',
    DEFAULT_ACCESS_KEY: 'Acesso padrão',
    WEB_APPS_KEY: 'Aplicações Web',
  },
};
