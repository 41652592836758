/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';
import { useParams } from 'react-router';
import { getWebAppsRequest, selectedClient } from 'modules/clients/actions';
import { WebAppsFields } from 'modules/clients/interfaces';
import {
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ConfigState } from 'modules/config/interfaces';
import i18n from 'i18n';
import { getAppIcon } from 'utils/helpers';

interface OwnProps {
  webApps: WebAppsFields;
  isLoading: boolean;
  config: ConfigState;
  getWebAppsRequest: (props) => void;
  selectedClient: (props) => void;
}

const Apps: React.FC<OwnProps> = ({
  webApps,
  isLoading,
  config,
  getWebAppsRequest,
  selectedClient,
}) => {
  const classes = makeStyles(styles(config?.appColor))();
  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    getWebAppsRequest({ id });
    // eslint-disable-next-line
  }, [id]);

  React.useEffect(() => {
    if (
      !webApps?.web_client_apps?.length &&
      webApps?.web_client_apps !== undefined
    ) {
      selectedClient(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, webApps]);

  return isLoading ||
    webApps?.web_client_apps === undefined ||
    webApps?.web_client_apps?.length === 0 ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.pageBaseLoad}
    >
      <CircularProgress size={45} />
    </Grid>
  ) : (
    <Grid container>
      <Grid container className={classes.pageBase}>
        <Grid
          item
          xs={6}
          className={clsx(classes.leftGrid, {
            [classes.leftGridShadow]: webApps?.web_client_apps?.length > 20,
          })}
        >
          <Grid item className={classes.alignAndFlex}>
            <Typography className={classes.appTitles}>
              {i18n.t('WEB_APPS_KEY')}
            </Typography>
          </Grid>
          <Grid item className={classes.alignAndFlexIcons}>
            {webApps?.web_client_apps?.map((app, index) => (
              <Grid
                key={index}
                className={classes.appsGrid}
                onClick={() => window.open(app?.url, '_blank')}
              >
                <Tooltip
                  arrow
                  title={`${app?.name} (${app?.url})`}
                  className={classes.appTitle}
                >
                  <Grid item container direction="column" alignItems="center">
                    <img
                      className={classes.image}
                      src={getAppIcon(app?.url)}
                      alt="internet icon"
                      height={50}
                    />
                    <Typography className={classes.appName}>
                      {app?.name}
                    </Typography>
                  </Grid>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item className={classes.alignAndFlex}>
            <Typography className={classes.appTitles}>
              {i18n.t('DESKTOP_APPS_KEY')}
            </Typography>
          </Grid>
          <Grid item className={classes.defaultApp}>
            <Tooltip arrow title={i18n.t('DEFAULT_ACCESS_KEY') || ''}>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                className={classes.defaultAppIcon}
              >
                <img
                  src="/images/internetAccess.png"
                  alt="default access icon"
                  height={80}
                />
                <Typography className={classes.appNameDesktop}></Typography>
                <Button
                  onClick={() => selectedClient(id)}
                  className={classes.accessButton}
                >
                  {i18n.t('ACCESS_KEY')}
                </Button>
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ clients, config }) => ({
  webApps: clients.webApps,
  isLoading: clients.isLoading,
  config,
});

export default connect(mapStateToProps, { getWebAppsRequest, selectedClient })(
  Apps
);
