import { takeLatest, put, select } from 'redux-saga/effects';
import { messagesRequest, messageClose, messagesOpen } from './actions';

function* messagesSaga({ payload }) {
  try {
    const {
      messages: { messagesList },
    } = yield select();

    const a = messagesList.find(item => item.message === payload.message);

    return yield !a && put(messagesOpen(payload));
  } catch (e) {
    return put(messageClose(payload.id));
  }
}

export default [takeLatest(messagesRequest, messagesSaga)];
