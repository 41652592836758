import palette from 'theme/palette';

export default {
  label: {
    color: palette().white,
    '&.MuiStepLabel-completed': {
      color: palette().white,
      fontWeight: 600,
    },
    '&.MuiStepLabel-active': {
      color: palette().superDarkBlue,
      fontWeight: 600,
    },
  },
};
