import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MiddleBar from 'components/MiddleBar';
import i18n from 'i18n';
import { configShowNewsChange } from 'modules/config/actions';
import React from 'react';
import { connect } from 'react-redux';
import palette from 'theme/palette';
import PathDictionary from '../PathDictionary';
import styles from './styles';

const MiddleBarConfigured = ({ path }) => {
  const classes = makeStyles(styles)();

  return (
    <Grid
      container
      justifyContent="space-between"
      className={classes.container}
    >
      <Grid item xs={10}>
        <Grid className={classes.titleContainer} container>
          <Typography className={classes.Title}>
            {PathDictionary[path[1]]}
          </Typography>
          <Typography className={classes.subTitle}>
            {PathDictionary[path[2]]}
          </Typography>
        </Grid>
        <MiddleBar
          upperSection={''}
          subSection={''}
          thirdSection={PathDictionary[path[3]]}
          color={palette().primary.main}
          secondaryColor="black"
          underDescription={
            <Typography style={{ fontSize: 14 }}>
              {window.location.href.includes('home')
                ? i18n.t('HERE_ENVIRONMENT_KEY')
                : ''}
            </Typography>
          }
          overrideClasses={{
            root: {
              paddding: '0 10px',
              height: 0,
              marginBottom: 30,
            },
            upperSection: {
              padding: '0px',
              fontFamily: 'Roboto',
              fontWeight: 900,
              color: '#979797',
              letterSpacing: 0,
              fontSize: 22,
            },
            subSection: {
              fontFamily: 'Roboto',
              fontSize: 22,
              fontWeight: 900,
              letterSpacing: 0,
            },
          }}
        />
      </Grid>
      <Grid item xs={2} style={{ marginTop: 30, maxWidth: 200 }}></Grid>
    </Grid>
  );
};

const mapStateToProps = ({ config }) => ({
  showNews: config.showNews,
});

export default connect(mapStateToProps, { configShowNewsChange })(
  MiddleBarConfigured
);
