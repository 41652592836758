import { colors } from '@material-ui/core';
import {
  white,
  error,
  success,
  waiting,
  validating,
  mediumGray,
  mainGray,
  lightBlue,
  lightGray,
  lightGreenGradient,
  lightOrange,
  mediumBlue,
  superDarkBlue,
  normalBlue,
  superLightBlue,
  blueGradient,
  yellowGradient,
  invertedYellowGradient,
  superLightGray,
  skySimpleGreen,
  primaryColor,
  skySimpleGray,
  waitingSigning,
  finalizing,
  approved,
  invalid,
  deleting,
  finished,
  charging,
} from './colors';
import { store } from 'store';

const black = '#000000';

export default () => {
  const { config } = store.getState();

  return {
    superLightGray,
    black,
    white,
    mediumGray,
    mainGray,
    lightBlue,
    lightGray,
    lightGreenGradient,
    blueGradient,
    lightOrange,
    mediumBlue,
    superDarkBlue,
    normalBlue,
    superLightBlue,
    yellowGradient,
    invertedYellowGradient,
    primaryColor,
    skySimpleGreen,
    skySimpleGray,
    primary: {
      contrastText: white,
      dark: primaryColor,
      main: primaryColor,
      light: primaryColor,
    },
    secondary: {
      contrastText: white,
      dark: skySimpleGreen,
      main: skySimpleGreen,
      light: skySimpleGreen,
    },
    error: {
      contrastText: white,
      dark: colors.red[900],
      main: colors.red[600],
      light: colors.red[400],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
      link: colors.blue[600],
    },
    link: colors.blue[800],
    icon: colors.blueGrey[600],
    background: {
      default: '#F4F6F8',
      paper: white,
    },
    divider: colors.grey[200],
    status: {
      error,
      success,
      waiting,
      validating,
      waitingSigning,
      finalizing,
      approved,
      invalid,
      deleting,
    },
    bordereauStatus: {
      error,
      waitingSigning,
      finalizing,
      charging,
      approved,
      finished,
      validating,
    },
    ...config,
  };
};
