import { Button } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import ChangeLanguage from 'components/ChangeLanguage';
import { authLogoutRequest } from 'modules/auth/actions';
import React from 'react';
import { connect } from 'react-redux';
import TopBar from 'skyone-lib/lib/Topbar';
import { store } from 'store';

interface Props {
  authLogoutRequest: (value: string) => void;
  logoUrl: string;
}

const TopBarConfigured: React.FC<Props> = ({ authLogoutRequest, logoUrl }) => {
  const [open, setOpen] = React.useState(false);
  const { auth } = store.getState();

  return (
    <>
      <TopBar
        logoUrl={logoUrl}
        sideMenuComponent=""
        sideMenuState={{ open, setOpen }}
        overrideClasses={{
          logo: {
            zIndex: 1001,
            maxWidth: 150,
            maxHeight: 80,
          },
          root: {
            height: 56,
            padding: '0 40px',
            borderBottom: 'solid 1px #EBEBED',
            background: '#fff',
          },
          companySelector: {
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            paddingRight: 14,
          },
        }}
        contextSelectorComponent={<div></div>}
        notificationsComponent={
          window.location.href.includes('home') && (
            <ChangeLanguage removeStyle />
          )
        }
        profileMenuComponent={
          <div>
            <Button
              onClick={() =>
                authLogoutRequest(auth.isAuthenticate?.access_token)
              }
            >
              <ExitToApp />{' '}
            </Button>
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = ({ config }) => ({
  logoUrl: config.logoUrl,
});

export default connect(mapStateToProps, {
  authLogoutRequest,
})(TopBarConfigured);
