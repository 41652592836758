import palette from '../palette';

export default {
  root: {
    height: 66,
    '&$selected': {
      backgroundColor: palette().background.default,
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette().background.default,
      },
    },
  },
};
