import Apis from 'apis';

const defaultSubdomains = ['us', 'sa', 'dev'];

export const api = Apis({
  baseURL:
    window.location.port !== ''
      ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`
      : `${window.location.protocol}//${window.location.hostname}/`,
});

export default {
  getConfig: () => {
    const hostname = window.location.hostname;
    const hostnameSeparated = hostname.split('.');

    if (hostnameSeparated.length >= 3) {
      const subdomain = hostnameSeparated[0];

      if (!defaultSubdomains.includes(subdomain)) {
        return `/clients/${subdomain}/config.json`;
      }

      return '/config.json';
    }

    return '/config.json';
  },
  getBaseConfig: () => {
    return '/config.json';
  },
};
