import { handleActions } from 'redux-actions';
import { InitialStateInterface } from './interfaces';
import Types from './types';

const initialState: InitialStateInterface = {
  isLoading: false,
};

export default handleActions(
  {
    [Types.PENDING_CONNECTIONS_REQUEST]: (state: InitialStateInterface) => ({
      ...state,
      isLoading: true,
    }),
    [Types.PENDING_CONNECTIONS_FULFILLED]: (
      state: InitialStateInterface,
      { payload }
    ) => ({
      ...state,
      isLoading: false,
      ...payload,
    }),
    [Types.PENDING_CONNECTIONS_REJECTED]: (state: InitialStateInterface) => ({
      ...state,
      isLoading: false,
    }),
    [Types.PENDING_SET_TIMER]: (state: InitialStateInterface, { payload }) => ({
      ...state,
      time: payload,
    }),
  },
  initialState
);
