/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { createStore, combineReducers, applyMiddleware, Store } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storageSession from 'redux-persist/lib/storage/session';
import { reducers } from 'modules';
import sagas from 'sagas';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const createRootReducer = (state: any, action: any) => {
  if (action.type === 'CLEAR_STORE') {
    setTimeout(() => {
      Object.keys(state).forEach(key =>
        storageSession.removeItem(`persist:${key}`)
      );
      const config = state?.config ? state.config : {};

      state = {};

      state.config = config;
    }, 1000);
  }

  const redu = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  return redu(state, action);
};

const persistConfig = {
  key: 'root',
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
  blacklist: ['uiLightBox', 'uiAlert', 'uiNotifications', 'router'],
};

// PARA PERSISITIR NO LOCAL STORE
const pReducer = persistReducer(persistConfig, createRootReducer);

const middlewares = [routeMiddleware, sagaMiddleware];
const logger = require('redux-logger').default;
middlewares.push(logger);

const store: Store<any> = createStore(
  pReducer,
  applyMiddleware(...middlewares)
);

sagaMiddleware.run(sagas);
const persistor = persistStore(store);

const rehydrationPromise = new Promise<void>((resolve, reject) => {
  resolve();
  reject();
});

export function rehydration() {
  return rehydrationPromise;
}

export { store, history, persistor };
