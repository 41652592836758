import axios from 'axios';
import { store, history } from 'store';
import { messagesRequest } from 'modules/messages/actions';
import dictionary from './dictionary';
import { ApiCall } from './interfaces';
import { isRequestOK } from 'utils/helpers';
import { authLogout } from 'modules/auth/actions';
import i18n from 'i18n';

export const TOKEN_TYPE = 'bearer';

const axiosErrorsInterceptor = (req: any) => {
  try {
    if (!req.response) {
      return Promise.reject(new Error());
    }

    if (!isRequestOK(req?.response?.status)) {
      if (req?.response.data?.error?.includes('INSTANCE_TURNING_ON')) {
        return history.push('/messages');
      }

      if (req.response.data?.error?.includes('NO_LICENSE_ENVIRONMENT')) {
        const {
          clients: { clients },
        } = store.getState();

        if (clients.length === 1) {
          store.dispatch(authLogout());
        } else {
          history.push('/home/environments');
        }
      }

      if (req?.response?.data?.error === 'NO_LICENSE_CLIENT') {
        store.dispatch(
          messagesRequest({
            message: i18n.t('NO_LICENSE_CLIENT'),
            type: 'error',
          })
        );
      } else if (
        req?.response.data?.error &&
        !req?.response.data?.error.includes('INVALID_COMPANY') &&
        !req?.response.data?.error.includes('COMPANY_ALREADY_REGISTERED')
      ) {
        const errMessage = req?.response?.data?.error?.split(':');
        const err =
          errMessage.length > 1 ? errMessage[1].trim() : errMessage[0];
        store.dispatch(
          messagesRequest({
            message: dictionary[err] ? dictionary[err] : err,
            type: 'error',
          })
        );
      }
    }

    if (req?.response?.status === 401) {
      store.dispatch(authLogout());
    }

    if (!isRequestOK(req?.response?.status)) {
      const {
        clients: { clients },
      } = store.getState();
      if (clients.length === 1) {
        if (req?.response?.data?.error !== 'USER_ACCESS_IS_RESTRICTED') {
          store.dispatch(authLogout());
        }
      }
    }

    return Promise.reject(req);
  } catch (e) {
    return Promise.reject(req);
  }
};

const request = async (params: any) => {
  const { auth } = await store.getState();
  axios.interceptors.response.use(response => response, axiosErrorsInterceptor);

  return axios({
    ...params,
    timeout: 15000,
    headers: {
      ...params.headers,
      Authorization: `Bearer ${auth.isAuthenticate?.access_token || ''}`,
    },
  });
};

export default ({ baseURL }: ApiCall) => ({
  get: (props: any) =>
    request({
      baseURL,
      ...props,
      method: 'GET',
    }),
  post: (props: any) =>
    request({
      baseURL,
      ...props,
      method: 'POST',
    }),
  put: (props: any) =>
    request({
      baseURL,
      ...props,
      method: 'PUT',
    }),
  patch: (props: any) =>
    request({
      baseURL,
      ...props,
      method: 'PATCH',
    }),
  delete: (props: any) =>
    request({
      baseURL,
      ...props,
      method: 'DELETE',
    }),
});
