/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { ConnectedRouter } from 'connected-react-router/immutable';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import Router from 'router';
import { history, rehydration } from 'store';

const useStyles = makeStyles(theme => ({
  root: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: 'translateZ(0)',
    '@media all and (-ms-high-contrast: none)': {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginBottom: 14,
    textAlign: 'center',
  },
  content: {
    marginBottom: 14,
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 4,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiButtonBase-root': {
      fontFamily: 'Roboto',
      minWidth: 176,
      padding: 8,
      margin: '0 8px',
      fontSize: 14,
      fontWeight: theme.typography.fontWeightMedium,
      borderRadius: 4,
    },
  },
}));

const CheckVersion = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  const rootRef = React.useRef(null);
  const classes = useStyles();

  return (
    <>
      {!isLatestVersion && (
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
          className={classes.modal}
          container={() => rootRef.current}
        >
          <div className={classes.paper}>
            <Typography className={classes.title} variant="body1">
              {i18n.t('MODAL_VERSION_TITLE_KEY')}
            </Typography>
            <Typography className={classes.content} variant="body2">
              {i18n.t('MODAL_VERSION_CONTENT_KEY')}
            </Typography>
            <Grid container justifyContent="center" className={classes.buttons}>
              <Button
                variant="text"
                style={{
                  color: 'white',
                  background: '#0064af',
                }}
                href="#"
                onClick={e => {
                  e.preventDefault();
                  emptyCacheStorage();
                }}
              >
                {i18n.t('MODAL_BUTTON_KEY')}
              </Button>
            </Grid>
          </div>
        </Modal>
      )}
    </>
  );
};

const App = () => {
  const [state, setState] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect((): void => {
    async function fetchData() {
      await rehydration();
      setState(true);
    }
    fetchData();
  }, [setState]);

  return (
    <ClearCacheProvider duration={60000} auto>
      <CheckVersion />
      <ConnectedRouter history={history}>
        {state ? <Router /> : <div> {i18n.t('LOADING_KEY')} </div>}
      </ConnectedRouter>
    </ClearCacheProvider>
  );
};

export default App;
