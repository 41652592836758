import { createAction } from 'redux-actions';
import Types from './types';

export const configRequest = createAction(Types.CONFIG_REQUEST);
export const configFulfilled = createAction(Types.CONFIG_FULFILLED);
export const configRejected = createAction(Types.CONFIG_REJECTED);
export const configTryGetBaseConfig = createAction(
  Types.CONFIG_TRY_GET_BASE_CONFIG
);

export const configShowNewsChange = createAction(Types.CONFIG_CHANGE_SHOW_NEWS);
