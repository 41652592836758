import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import { store } from 'store';
import App from 'App';
import Messages from 'components/Messages';
import './fonts.css';

const render = (AppComponent: React.FC<{}>) => {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Messages />
        <AppComponent />
      </Provider>
    </I18nextProvider>,

    document.getElementById('root')
  );
};

render(App);
