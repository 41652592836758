import { mainGray } from '../colors';

export default {
  gutterBottom: {
    marginBottom: 8,
  },
  h4: {
    color: mainGray,
  },
};
