import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import i18n, { changeLanguage } from 'i18n';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import options from './options';
import styles from './styles';
import { Language } from '@material-ui/icons';
import ApplicationState from 'store/interfaces';
import { ConfigState } from 'modules/config/interfaces';
import {
  IconButton,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Typography,
  Popper,
  Paper,
  Slide,
} from '@material-ui/core';

interface ChangeLanguageProps {
  config: ConfigState;
  removeStyle?: boolean;
}

const ChangeLanguage: React.FC<ChangeLanguageProps> = ({
  config,
  removeStyle,
}) => {
  const classes = makeStyles(styles(config?.backgroundColor))();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);

  const language = i18n.language || 'pt';

  return (
    <Grid
      container
      justifyContent="flex-end"
      className={
        removeStyle ? classes.removeStyles : classes.changeLanguageContainer
      }
      alignItems="center"
    >
      <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
        <>
          <IconButton
            className={classes.languageButton}
            onClick={() => setOpenMenu(!openMenu)}
            ref={anchorRef}
          >
            <Language />
          </IconButton>

          <Popper
            open={openMenu}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            transition
            disablePortal
            modifiers={{
              offset: { enabled: true, offset: '0, 4' },
            }}
          >
            {({ TransitionProps }) => (
              <Slide {...TransitionProps}>
                <Paper>
                  <MenuList className={classes.languageList}>
                    {options(changeLanguage).map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          className={`${classes.languageItem} ${
                            item?.language.test(language)
                              ? classes.selectedLanguage
                              : ''
                          }`}
                          onClick={() => {
                            item?.action();
                            setOpenMenu(false);
                          }}
                        >
                          <Typography>{item?.text}</Typography>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Paper>
              </Slide>
            )}
          </Popper>
        </>
      </ClickAwayListener>
    </Grid>
  );
};

const mapStateToProps = ({ config }: ApplicationState) => ({
  config,
});

export default connect(mapStateToProps)(ChangeLanguage);
