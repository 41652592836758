import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import React from 'react';
import { connect } from 'react-redux';
import { layouts } from 'skyone-lib';
import MiddleBarConfigured from './MiddleBarConfigured';
import TopBarConfigured from './TopBarConfigured';

const { Skeleton } = layouts;

interface OwnProps {
  component: React.ReactNode;
  path: string;
  clients: any;
  width: any;
}

const shouldRenderMiddleBar = (
  path: string,
  renderMiddleBar: boolean,
  clients: any
) => {
  const isLoadingClients = clients.isLoading || clients.isLoadingAttempt;
  const hasSingleClient = clients.clients.length === 1;
  return (
    renderMiddleBar &&
    !isLoadingClients &&
    !hasSingleClient && <MiddleBarConfigured path={path} />
  );
};

const Dashboard: React.FC<OwnProps> = ({ component, path, clients, width }) => {
  const renderMiddleBar =
    window.location.href.includes('environments') ||
    window.location.href.includes('virtualizerChoice');

  const isMobile = isWidthDown('md', width, true);

  return (
    <Grid container>
      <Skeleton
        path={path}
        buttonsBarComponent={<div></div>}
        middleBarComponent={shouldRenderMiddleBar(
          path,
          renderMiddleBar,
          clients
        )}
        topBarComponent={<TopBarConfigured />}
        overrideClasses={{
          children: {
            padding: `10px ${isMobile ? 10 : 40}px`,
            justifyContent: 'center',
          },
        }}
      >
        {component}
      </Skeleton>
    </Grid>
  );
};

const mapStateToProps = ({ router, clients }) => ({
  path: router.location?.pathname?.split('/'),
  clients,
});

export default connect(mapStateToProps)(withWidth()(Dashboard));
