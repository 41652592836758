import { createStyles } from '@material-ui/styles';
import palette from 'theme/palette';
import { Theme } from '@material-ui/core';

const styles = backgroundColor => ({ breakpoints }: Theme) =>
  createStyles({
    removeStyles: {
      marginLeft: 8,
    },
    changeLanguageContainer: {
      marginRight: 40,

      [breakpoints.down('md')]: {
        marginRight: 0,
      },
    },
    popper: {
      marginTop: 15,
      width: 150,
      backgroundColor: '#FFFFFF',
      border: `1px solid #EBEBED`,
      borderTop: 'none',
      zIndex: 1300,
      padding: 20,
      cursor: 'pointer',
    },

    languageButton: {
      color: backgroundColor,
      [breakpoints.down('md')]: {
        backgroundColor: '#fff',
        borderRadius: 8,
        '&:hover': {
          backgroundColor: '#fff',
        },
      },
    },
    languageList: {
      padding: 0,
    },
    languageItem: {
      '& .MuiTypography-root': {
        color: palette().mediumGray,
        fontSize: 14,
      },
      borderBottom: `1px solid #eeeeee`,
    },
    selectedLanguage: {
      '& .MuiTypography-root': {
        fontWeight: 'bold',
        color: backgroundColor,
      },
    },
  });

export default styles;
