import Dashboard from 'containers/Dashboard';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  isAuthenticate,
  noHeader,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const renderComponent = isAuthenticate?.access_token || false;
        if (!renderComponent)
          return <Redirect to={{ pathname: '/', state: { from: location } }} />;

        return noHeader ? (
          <Component {...rest} />
        ) : (
          <Dashboard component={<Component {...rest} />} />
        );
      }}
    />
  );
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticate: auth.isAuthenticate,
});

export default connect(mapStateToProps)(PrivateRoute);
