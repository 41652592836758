/* eslint-disable @typescript-eslint/camelcase */
import Apis from 'apis';

export const api = Apis({
  baseURL: process.env.REACT_APP_URL || '',
});

export default {
  login_attempt: client_uuid => `login_attempt?cli_uuid=${client_uuid}`,
  callPlugin: client_uuid => `/call_plugin?cli_uuid=${client_uuid}`,
};
