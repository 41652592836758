import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import styles from './styles';

interface OwnProps {
  upperSection: string;
  subSection: string;
  thirdSection: string;
  color: string;
  overrideClasses?: any;
  secondaryColor?: string;
  underDescription?: React.ReactNode;
}

const MiddleBar: React.FC<OwnProps> = ({
  upperSection,
  subSection,
  thirdSection,
  color,
  overrideClasses,
  secondaryColor,
  underDescription,
}) => {
  const theme = useTheme();
  const useStyles = makeStyles(styles(overrideClasses, theme));
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12}>
        <Typography
          className={subSection ? classes.upperSection : classes.subSection}
          style={{ color: secondaryColor || color }}
        >
          {upperSection?.toUpperCase()}
          {subSection && (
            <span
              className={classes.subSection}
              style={{ color: thirdSection ? secondaryColor || color : color }}
            >
              {` ${subSection?.toUpperCase()}`}
            </span>
          )}
          {thirdSection && (
            <span className={classes.subSection} style={{ color }}>
              {` > ${thirdSection?.toUpperCase()}`}
            </span>
          )}
        </Typography>
      </Grid>
      {underDescription && (
        <Grid item xs={12}>
          {underDescription}
        </Grid>
      )}
    </Grid>
  );
};

export default MiddleBar;
