import { store } from 'store';

export default {
  root: {
    fontSize: 10,
    minWidth: 16,
  },
  contained: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: store.getState().config.primary.dark,
    color: 'white',
    borderRadius: '5px',
    fontSize: 14,
    width: '100%',
    fontWeight: 300,
  },
  containedSizeLarge: {
    fontSize: 14,
    fontWeight: 500,
  },
  outlined: {
    border: `solid 1px ${store.getState().config.primary.main}`,
  },
  outlinedSizeLarge: {
    fontSize: 14,
    fontWeight: 500,
  },
};
