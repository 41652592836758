/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/camelcase */
import { handleActions } from 'redux-actions';
import { ClientsState } from './interfaces';
import Types from './types';

const initialState: ClientsState = {
  isLoading: false,
  clients: [],
  warning_message: undefined,
  maintenance_message: undefined,
  uuid: '',
  isLoadingAttempt: false,
  time: 0,
  startingHtml5Instance: false,
  data: null,
  iframe: {
    type: '',
    iframe_value: '',
  },
  webApps: [],
};

export default handleActions(
  {
    [Types.CLIENTS_REQUEST]: (state: ClientsState) => ({
      ...initialState,
      isLoading: true,
    }),
    [Types.CLIENTS_FULFILLED]: (state: ClientsState, { payload }) => ({
      ...state,
      isLoading: false,
      clients: payload,
    }),
    [Types.CLIENTS_REJECTED]: (state: ClientsState) => ({
      ...state,
      isLoading: false,
    }),
    [Types.CLIENT_SELECTED]: (state: ClientsState, { payload }) => ({
      ...state,
      uuid: payload || state.uuid,
    }),
    [Types.CLIENT_SELECTED_FULFILLED]: (state: ClientsState, { payload }) => ({
      ...state,
      ...payload,
    }),
    [Types.CLIENT_SELECTED_REJECTED]: (state: ClientsState) => ({
      ...state,
    }),

    [Types.CLIENT_LOGIN_ATTEMPT_REQUEST]: (state: ClientsState) => ({
      ...state,
      isLoadingAttempt: true,
    }),
    [Types.CLIENT_LOGIN_ATTEMPT_FULFILLED]: (
      state: ClientsState,
      { payload }
    ) => ({
      ...state,
      time: payload?.time ? payload.time - 1 : 0,
      data: payload?.data,
    }),
    [Types.CLIENT_LOGIN_ATTEMPT_REJECTED]: (state: ClientsState) => ({
      ...state,
      isLoadingAttempt: false,
    }),
    [Types.CLIENT_SET_DATA]: (state: ClientsState, { payload }) => ({
      ...state,
      isLoadingAttempt: false,
      data: payload,
    }),
    [Types.CLIENT_SESSION_REQUEST]: (state: ClientsState) => ({
      ...state,
    }),
    [Types.CLIENT_SET_LOADING]: (state: ClientsState, { payload }) => ({
      ...state,
      isLoading: payload,
      startingHtml5Instance: true,
    }),
    [Types.CLIENTS_RESET_STATE]: (state: ClientsState) => ({
      ...initialState,
      uuid: state.uuid,
    }),
    [Types.REGISTER_SELECTED_VIRTUALIZER_REQUEST]: (state: ClientsState) => ({
      ...initialState,
      isLoading: true,
    }),
    [Types.REGISTER_SELECTED_VIRTUALIZER_FULFILLED]: (state: ClientsState) => ({
      ...state,
      isLoading: false,
    }),
    [Types.REGISTER_SELECTED_VIRTUALIZER_REJECTED]: (state: ClientsState) => ({
      ...state,
      isLoading: false,
    }),
    [Types.REGISTER_SELECTED_VIRTUALIZER_LINUX_REQUEST]: (
      state: ClientsState
    ) => ({
      ...initialState,
      isLoading: true,
    }),
    [Types.SET_IFRAME]: (state: ClientsState, { payload }) => ({
      ...state,
      isLoading: false,
      iframe: payload,
    }),
    [Types.GET_WEB_APPS_REQUEST]: (state: ClientsState) => ({
      ...initialState,
      isLoading: true,
    }),
    [Types.GET_WEB_APPS_FULFILLED]: (state: ClientsState, { payload }) => ({
      ...state,
      isLoading: false,
      webApps: payload,
    }),
    [Types.GET_WEB_APPS_REJECTED]: (state: ClientsState) => ({
      ...state,
      isLoading: false,
    }),
  },
  initialState
);
