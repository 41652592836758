import { mapFiles, getFiles } from 'utils/files';

export const reducers = mapFiles(
  getFiles(require.context('./', true, /reducer.ts/))
);
export const actions = mapFiles(
  getFiles(require.context('./', true, /actions.ts/))
);
export const sagas = mapFiles(
  getFiles(require.context('./', true, /sagas.ts/))
);

export default {
  reducers,
  actions,
  sagas,
};
