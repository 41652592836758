import { createTheme } from '@material-ui/core/styles';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = config => {
  const finalPalette = palette(config);

  return createTheme({
    palette: finalPalette,
    typography,
    overrides,
  });
};

export default theme;
