/* eslint-disable @typescript-eslint/camelcase */
import { put, all, select, take } from 'redux-saga/effects';
import Types from './types';
import { push } from 'connected-react-router';
import {
  clientLoginAttemptFulfilled,
  clientLoginAttemptRejected,
  clientSetData,
  clientSetLoading,
  registerSelectedVirtualizerLinuxRequest,
  setIframe,
} from './actions';
import endpoints, { api } from './endpoints';
import { messagesRequest } from 'modules/messages/actions';
import { instancePluginSet } from 'modules/instance/actions';
import { pendingConnectionsSetTimer } from 'modules/pendingConnections/actions';
import i18n from 'i18n';
import { store } from 'store';
import { authLogout } from 'modules/auth/actions';
import platform from 'platform';

export function* pluginHandle(data, status, uuid) {
  const {
    clients: { clients },
  } = yield select();
  if (status === 202 && data.error === 'NO_INSTANCE_AVAILABLE') {
    if (clients.length === 1) {
      yield put(authLogout());
    } else {
      yield put(push('/home/environments'));
    }

    return yield all([
      put(clientLoginAttemptRejected()),
      put(
        messagesRequest({
          type: 'error',
          message: i18n.t('ENVIRONMENT_NOT_CONFIGURED_KEY'),
        })
      ),
    ]);
  }

  if (status === 202 && data.error === 'NO_LICENSE_ENVIRONMENT') {
    const {
      clients: { clients },
    } = yield store.getState();

    if (clients.length === 1) {
      yield put(authLogout());
    } else {
      yield put(push('/home/environments'));
    }

    return yield all([
      put(clientLoginAttemptRejected()),
      put(
        messagesRequest({
          type: 'error',
          message: i18n.t('NO_LICENSE_ENVIRONMENT'),
        })
      ),
    ]);
  }

  if (status === 206 && data.error === 'HAS_PENDING_CONNECTION') {
    return yield all([
      put(clientLoginAttemptRejected()),
      put(pendingConnectionsSetTimer(data.time)),
      put(push('/pendingConnection')),
    ]);
  }

  if (
    data.licenses === 'SUCCESS' &&
    data.instances === 'SUCCESS' &&
    data.pending_connection === 'SUCCESS'
  ) {
    const response = yield api.get({
      url: endpoints.callPlugin(uuid),
    });

    if (response.data.virtualizer === 'TSPLUS') {
      const isHttps = response.data.use_dns ? 'https' : 'http';

      yield put(clientSetLoading(true));

      let redirectUrl: string;
      if (platform.name?.includes('Chrome')) {
        window.name = response.data.window_name;
        redirectUrl = `${isHttps}://${response.data.instance}:${response.data.webport}/software/html5.html`;
      } else {
        redirectUrl = endpoints.validateSession(
          isHttps,
          response.data.window_name,
          response.data.instance,
          response.data.webport
        );
      }
      window.location.href = redirectUrl;
      return redirectUrl;
    } else if (response.data.virtualizer === 'TSPLUS_REMOTE_APP') {
      const hasPlugin = localStorage.getItem('hasPlugin');
      let redirectUrl: string;
      if (hasPlugin === 'false' || hasPlugin === null) {
        return yield all([
          put(push('/firstAccess')),
          put(clientLoginAttemptFulfilled({ data: response.data })),
          put(instancePluginSet(`autoskyplugin://${response.data.iframe}`)),
        ]);
      } else {
        yield put(push('/launching/0'));
        yield put(clientLoginAttemptFulfilled({ data: response.data }));
        redirectUrl = `autoskyplugin://${response.data.iframe}`;
        window.location.href = redirectUrl;
        return redirectUrl;
      }
    } else if (response.data.virtualizer === 'TSPLUS_ALL') {
      const {
        data: {
          data: {
            TSPLUS: { instance, webport, window_name, use_dns },
            REMOTE_APP: { iframe },
          },
          environment_uuid,
          ssid,
          virtualizer,
        },
      } = response;

      const urlObj = new URL(response.config.url);
      const cliUuid = urlObj.searchParams.get('cli_uuid');

      yield put(
        clientSetData({
          TSPLUS: { instance, webport, window_name, use_dns },
          iframe,
          environment_uuid,
          ssid,
          virtualizer,
          cliUuid,
        })
      );
      const isHttps = use_dns ? 'https' : 'http';
      if (platform.os?.family?.includes('Linux')) {
        yield put(
          registerSelectedVirtualizerLinuxRequest({
            environment_uuid: environment_uuid,
            client_uuid: cliUuid,
            ssid: ssid,
            virtualizer: 'TSPLUS',
          })
        );

        yield take(Types.REGISTER_SELECTED_VIRTUALIZER_REQUEST_COMPLETED);

        yield put(clientSetLoading(true));
        if (platform.name?.includes('Chrome')) {
          window.name = window_name;
          window.location.href = `${isHttps}://${instance}:${webport}/software/html5.html`;
        } else {
          window.location.href = endpoints.validateSession(
            isHttps,
            window_name,
            instance,
            webport
          );
        }
        return;
      } else {
        return yield put(push(`/virtualizerChoice`));
      }
    } else if (
      response.data.virtualizer === 'CONNECT' ||
      response.data.virtualizer === 'CONNECT_DIRECT'
    ) {
      const hasPlugin = localStorage.getItem('hasPlugin');
      if (hasPlugin === 'false' || hasPlugin === null) {
        return yield all([
          put(push('/firstAccess')),
          put(clientLoginAttemptFulfilled(data)),
          put(instancePluginSet(`autoskyplugin://${response.data.iframe}`)),
          put(
            setIframe({ type: 'CONNECT', iframe_value: response.data.iframe })
          ),
        ]);
      } else {
        yield all([
          put(instancePluginSet(`autoskyplugin://${response.data.iframe}`)),
          put(
            setIframe({ type: 'CONNECT', iframe_value: response.data.iframe })
          ),
        ]);

        window.location.href = `autoskyplugin://${response.data.iframe}`;
        return yield all([
          put(push('/launching/0')),
          put(
            setIframe({ type: 'CONNECT', iframe_value: response.data.iframe })
          ),
        ]);
      }
    } else {
      if (clients.length === 1) {
        yield put(authLogout());
      } else {
        yield put(
          messagesRequest({
            type: 'error',
            message: i18n.t('VIRTUALIZER_NOT_FOUND_KEY'),
          })
        );
        window.location.reload();
      }
      return;
    }
  }
}
