/* eslint-disable @typescript-eslint/camelcase */
import { takeLatest, put, all, select } from 'redux-saga/effects';
import { isRequestOK } from 'utils/helpers';
import { push } from 'connected-react-router';
import {
  pendingConnectionsFulfilled,
  pendingConnectionsRejected,
  pendingConnectionsRequest,
} from './actions';
import endpoints, { api } from './endpoints';

function* pendingConnectionsRequestSaga() {
  try {
    const {
      clients: { uuid },
    } = yield select();

    const { status, data } = yield api.post({
      url: endpoints.disconnectUser(),
      data: {
        cli_uuid: uuid,
      },
    });

    return yield isRequestOK(status)
      ? all([put(pendingConnectionsFulfilled(data)), put(push('/reconnect'))])
      : all([put(pendingConnectionsRejected())]);
  } catch (responseWithError) {
    return yield responseWithError &&
    responseWithError.response &&
    responseWithError.response.status === 452
      ? put(pendingConnectionsRejected())
      : all([put(pendingConnectionsRejected())]);
  }
}

export default [
  takeLatest(pendingConnectionsRequest, pendingConnectionsRequestSaga),
];
