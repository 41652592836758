import { createStyles } from '@material-ui/styles';

const styles = appColor => () =>
  createStyles({
    pageBase: {
      padding: 32,
      margin: 32,
      borderRadius: 16,
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      height: '80vh',
      backgroundColor: '#fafafa',
    },
    pageBaseLoad: {
      padding: 32,
      margin: 32,
      borderRadius: 16,
      height: '80vh',
      backgroundColor: '#fafafa',
    },
    alignAndFlex: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 24,
    },
    alignAndFlexIcons: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    appTitles: { color: appColor, fontSize: 28 },
    leftGrid: {
      borderRight: '1px solid darkgray',
      maxHeight: '70vh',
    },
    leftGridShadow: {
      boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
      overflowY: 'scroll',
    },
    appsGrid: { margin: 18, cursor: 'pointer' },
    appTitle: {
      maxWidth: 100,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 85,
    },
    defaultApp: {
      height: '65%',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    defaultAppIcon: { width: '50%' },
    accessButton: {
      fontSize: 14,
      padding: '6px 24px',
      backgroundColor: appColor,
      color: 'white',
      marginTop: 16,
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
      '&:hover': {
        backgroundColor: appColor,
      },
    },
    appName: {
      display: 'flow',
      overflow: 'hidden',
      fontSize: 14,
      marginTop: 4,
      whiteSpace: 'nowrap',
      width: 85,
      textOverflow: 'ellipsis',
      justifyContent: 'space-around',
      maxWidth: 85,
      textAlign: 'center',
    },
    appNameDesktop: {
      display: 'flow',
      overflow: 'hidden',
      fontSize: 14,
      marginTop: 4,
      whiteSpace: 'nowrap',
      width: 120,
      textOverflow: 'ellipsis',
      justifyContent: 'space-around',
      maxWidth: 120,
      textAlign: 'center',
    },
    image: { width: '100%', objectFit: 'contain' },
  });
export default styles;
