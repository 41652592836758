import { handleActions } from 'redux-actions';
import { InitialStateInterface } from './interfaces';
import Types from './types';

const initialState: InitialStateInterface = {};

export default handleActions(
  {
    [Types.INSTANCE_PLUGIN_SET]: (
      state: InitialStateInterface,
      { payload }
    ) => ({
      ...state,
      plugin: payload,
    }),
  },
  initialState
);
