import { takeLatest, put, all } from 'redux-saga/effects';
import { isRequestOK, isValidJSON } from 'utils/helpers';
import {
  configFulfilled,
  configRejected,
  configRequest,
  configTryGetBaseConfig,
} from './actions';
import endpoints, { api } from './endpoints';

function* configRequestSaga() {
  try {
    const { status, data } = yield api.get({
      url: endpoints.getConfig(),
    });

    return yield isRequestOK(status) && isValidJSON(data)
      ? all([put(configFulfilled(data))])
      : all([put(configTryGetBaseConfig())]);
  } catch (responseWithError) {
    return yield responseWithError &&
    responseWithError.response &&
    responseWithError.response.status === 452
      ? put(configRejected())
      : all([put(configRejected())]);
  }
}

function* configTryGetBaseConfigSaga() {
  try {
    const { status, data } = yield api.get({
      url: endpoints.getBaseConfig(),
    });

    return yield isRequestOK(status) && isValidJSON(data)
      ? all([put(configFulfilled(data))])
      : all([put(configRejected())]);
  } catch (responseWithError) {
    return yield responseWithError &&
    responseWithError.response &&
    responseWithError.response.status === 452
      ? put(configRejected())
      : all([put(configRejected())]);
  }
}

export default [
  takeLatest(configRequest, configRequestSaga),
  takeLatest(configTryGetBaseConfig, configTryGetBaseConfigSaga),
];
