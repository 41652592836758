import { handleActions } from 'redux-actions';
import MessageTypes from './interfaces';
import { MessagesState } from './types';

const initialState: MessagesState = {
  messagesList: [],
};

export default handleActions(
  {
    [MessageTypes.MESSAGES_REQUEST]: (state: MessagesState) => ({
      ...state,
      isLoading: true,
    }),
    [MessageTypes.MESSAGES_OPEN]: (state: MessagesState, { payload }) => {
      return {
        ...state,
        isLoading: false,
        messagesList: [
          ...state.messagesList,
          {
            id: state.messagesList.length + 1,
            message: payload.message,
            type: payload.type,
            hasDisplayed: false,
          },
        ],
      };
    },
    [MessageTypes.MESSAGES_CLOSE]: (state: MessagesState, { payload }) => ({
      ...state,
      isLoading: false,
      messagesList: state.messagesList.filter(item => item.id !== payload),
    }),
  },
  initialState
);
