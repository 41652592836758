import { createAction } from 'redux-actions';
import Types from './types';

export const pendingConnectionsRequest = createAction(
  Types.PENDING_CONNECTIONS_REQUEST
);
export const pendingConnectionsFulfilled = createAction(
  Types.PENDING_CONNECTIONS_FULFILLED
);
export const pendingConnectionsRejected = createAction(
  Types.PENDING_CONNECTIONS_REJECTED
);

export const pendingConnectionsSetTimer = createAction(Types.PENDING_SET_TIMER);
