import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { pt, en, es } from './locales';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en-US': en,
      'pt-BR': pt,
      es,
    },
    detection: {
      order: ['cookie', 'localStorage', 'sessionStorage', 'navigator'],

      lookupCookie: 'language',
      lookupLocalStorage: 'language',
      lookupSessionStorage: 'language',

      // cache user language on
      caches: ['sessionStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      cookieMinutes: 43200,
    },
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
  });

const changeLanguage = language => {
  i18n.changeLanguage(language);
  sessionStorage.setItem('language', language);
  localStorage.setItem('language', language);
  setTimeout(() => {
    window.location.reload();
  }, 300);
};

export default i18n;
export { changeLanguage };
